import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";

import { forwardRef, useEffect } from "react";

import { CalendarIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.date()
    .required("Start date is required")
    .nullable()
    .test(
      "is-before-end",
      "Start date must be before end date",
      function (value) {
        const { end_date } = this.parent;
        return !value || !end_date || new Date(value) < new Date(end_date);
      }
    ),
  end_date: Yup.date()
    .required("End date is required")
    .nullable()
    .test(
      "is-after-start",
      "End date must be after start date",
      function (value) {
        const { start_date } = this.parent;
        return !value || !start_date || new Date(value) > new Date(start_date);
      }
    ),
});

export default function Filter(props) {
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const formik = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          start_date: values.start_date,
          end_date: values.end_date,
        },
      });
    },
  });

  useEffect(() => {
    let dates = formik.values;
    if (dates.start_date || dates.end_date) {
      setMultipleQueryParams({
        startDate: parseValue(dates.start_date),
        endDate: parseValue(dates.end_date),
        page: 1,
      });

      // props.filter({
      //   page: 1,
      //   startDate: parseValue(dates.start_date),
      //   endDate: parseValue(dates.end_date),
      // });
      // props.setPage(1);
    } else {
      setMultipleQueryParams({
        startDate: null,
        endDate: null,
        // page: 1,
      });
      // props.filter({
      //   page: 1,
      //   startDate: null,
      //   endDate: null,
      // });
      // props.setPage(1);
    }
  }, [formik.values.start_date, formik.values.end_date]);

  const parseValue = (date) => {
    //return date ? date.toISOString().split("T")[0] : null;

    if (!date) return null;

    try {
      const parsedDate =
        typeof date === "object" && date.year && date.month && date.day
          ? new Date(date.year, date.month - 1, date.day)
          : new Date(date);

      return !isNaN(parsedDate) ? parsedDate.toISOString().split("T")[0] : null;
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  };

  // Custom input component to include calendar icon
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      onClick={onClick}
      className="relative w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 flex items-center"
    >
      <input
        ref={ref}
        value={value}
        onChange={onChange}
        className="flex-grow outline-none w-full"
        placeholder="Select date"
        readOnly
      />
      <CalendarIcon className="h-5 w-5 text-gray-400 ml-2" />
    </div>
  ));

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date || ""}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                />

                {/* <DatePicker
                  selected={formik.values.start_date}
                  onChange={(date) => formik.setFieldValue("start_date", date)}
                  onBlur={formik.handleBlur}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInput />}
                  className="w-full"
                />
                {formik.touched.start_date && formik.errors.start_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.start_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date || ""}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                />
                {/* <DatePicker
                  selected={formik.values.end_date}
                  onChange={(date) => formik.setFieldValue("end_date", date)}
                  onBlur={formik.handleBlur}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInput />}
                  className="w-full"
                />
                {formik.touched.end_date && formik.errors.end_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.end_date}
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
