import * as Yup from "yup";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";
import useQueryParams from "../../utils/query_params";

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

export default function Filter(props) {
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();

  const [statusOptions, setStausOptions] = useState([
    { label: "All", value: "all" },
    { label: "Ready to Invoice", value: "Ready to Invoice" },
    { label: "Request for GR sent", value: "Request for GR sent" },
    { label: "Invoice Sent", value: "Invoice Sent" },
    { label: "Follow-up 1", value: "Follow-up 1" },
    { label: "Follow-up 2", value: "Follow-up 2" },
    { label: "Follow-up 3", value: "Follow-up 3" },
    { label: "Escalated", value: "Escalated" },
    { label: "Payment Received", value: "Payment Received" },
  ]);

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [programNameOptions, setProgramNameOptions] = useState([]);

  const [clientName, setClientName] = useState(
    getQueryParam("client")
      ? {
          value: getQueryParam("client"),
          label: capitalizeFirstLetter(getQueryParam("client")),
        }
      : { label: "All", value: "all" }
  );

  const [programName, setProgramName] = useState(
    getQueryParam("program")
      ? {
          value: getQueryParam("program"),
          label: capitalizeFirstLetter(getQueryParam("program")),
        }
      : { label: "All", value: "all" }
  );

  const [status, setStatus] = useState(
    getQueryParam("status")
      ? {
          label: getQueryParam("statusLabel"),
          value: getQueryParam("status"),
        }
      : { label: "All", value: "all" }
  );

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      status: "all",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          start_date: values.start_date,
          end_date: values.end_date,
          status: values.status,
        },
      });
    },
  });

  // useEffect(() => {
  //   let dates = formik.values;
  //   if (dates.start_date != "" || dates.end_date != "") {
  //     props.filter({
  //       page: 1,
  //       startDate: parseValue(dates.start_date),
  //       endDate: parseValue(dates.end_date),
  //       status: dates.status,
  //     });
  //     props.setPage(1);
  //   } else {
  //     props.filter({
  //       page: 1,
  //       status: dates.status,
  //     });
  //     props.setPage(1);
  //   }
  // }, [formik.values.start_date, formik.values.end_date, formik.values.status]);

  const parseValue = (string) => {
    if (string == "") {
      return null;
    } else {
      return string;
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:grid-cols-5 lg:grid-cols-5 items-start">
            <div className="col-span-1 sm:col-span-3 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={(event) => {
                    formik.handleChange(event); // Call formik's onChange
                    setMultipleQueryParams({
                      startDate: event.target.value, // Set the value in query params
                      endDate: null,
                      status: "all",
                      statusLabel: "All",
                      page: 1,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                />

                {/* {formik.touched.start_date && formik.errors.start_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.start_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  onChange={(event) => {
                    formik.handleChange(event); // Call formik's onChange
                    setMultipleQueryParams({
                      endDate: event.target.value, // Set the value in query params
                      status: "all",
                      statusLabel: "All",
                      page: 1,
                    });
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  max="9999-12-31"
                />

                {/* {formik.touched.end_date && formik.errors.end_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.end_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="react-select-client_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Client Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    // refetch({
                    //   client: selected.value,
                    //   program: "all",
                    //   status: "all",
                    // });
                    setMultipleQueryParams({
                      client: selected.value,
                      program: "all",
                      status: "all",
                      page: 1,
                    });
                    setClientName(selected);
                    setProgramName({ label: "All", value: "all" });
                    setStatus({ label: "All", value: "all" });
                    // props.refetch({
                    //   page: 1,
                    //   client: selected.value,
                    //   program: "all",
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="react-select-program_name-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Program Name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={programNameOptions}
                  name="program_name"
                  id="program_name"
                  instanceId="program_name"
                  value={{
                    label: programName.label,
                    value: programName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("program_name", selected.value);
                    // refetch({
                    //   program: selected.value,
                    //   client: clientName.value,
                    //   status: "all",
                    // });
                    setMultipleQueryParams({
                      program: selected.value,
                      status: "all",
                      page: 1,
                    });
                    setProgramName({
                      label: selected.label,
                      value: selected.value,
                    });
                    setStatus({ label: "All", value: "all" });
                    // props.refetch({
                    //   page: 1,
                    //   client: clientName.value,
                    //   program: selected.value,
                    //   status: "all",
                    // });
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="react-select-status-input"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                    setMultipleQueryParams({
                      status: selected.value,
                      statusLabel: selected.label,
                      page: 1,
                    });
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
