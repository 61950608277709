import { useLocation, useNavigate } from "react-router-dom";

function useQueryParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const setQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);

    if (value) {
      params.set(key, value); // Add or update
    } else {
      params.delete(key); // Remove if value is empty
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const getAllQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams;
  };

  const setMultipleQueryParams = (newParams) => {
    const params = new URLSearchParams(location.search);

    Object.entries(newParams).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        params.set(key, value); // Add or update
      } else {
        params.delete(key); // Remove if value is null or undefined
      }
    });
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  return {
    getQueryParam,
    getAllQueryParams,
    setQueryParam,
    setMultipleQueryParams,
  };
}

export default useQueryParams;
