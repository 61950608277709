import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

import Filter from "./filter";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import formatNumber from "../../utils/formatNumber";
import moment from "moment";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useQueryParams from "../../utils/query_params";

const validationSchema = Yup.object({
  paidDate: Yup.date().required("Required"),
});

const ADMIN_ROSTERS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $startDate: String, $endDate: String) {
    adminRosters(
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
    ) {
      entries {
        description
        id
        netAmount
        paidDate
        program {
          id
          clientName
          programName
        }
        personnel {
          firstName
          lastName
        }
        rate
        rateTaxAmount
        remarks
        roleType {
          referenceType
          referenceDescription
        }
        rosterDate
        status
        tax
      }
      pageNumber
      pageSize
      totalPages
      totalEntries
    }
  }
`;

const ROSTER_MARK_AS_PAID_MUTATION = gql`
  mutation ($id: String!, $paidDate: String!) {
    rosterMarkAsPaid(id: $id, paidDate: $paidDate) {
      roster {
        id
      }
    }
  }
`;

const ROSTER_MARK_ALL_AS_PAID_MUTATION = gql`
  mutation ($ids: [String!], $paidDate: String!) {
    rosterMarkAllAsPaid(ids: $ids, paidDate: $paidDate) {
      status
    }
  }
`;

export default function RosterPayables() {
  const [roster_mark_as_paid] = useMutation(ROSTER_MARK_AS_PAID_MUTATION, {
    onCompleted: (data) => {
      if (data && data.rosterMarkAsPaid) {
        refetch();
      } else {
        console.error("Error marking advance as paid:", error);
      }
    },
    onError: (error) => {
      console.error("Error marking advance as paid:", error);
    },
  });

  const [roster_mark_all_as_paid] = useMutation(
    ROSTER_MARK_ALL_AS_PAID_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.rosterMarkAllAsPaid) {
          refetch();
        } else {
          console.error("Error marking advances as paid:", error);
        }
      },
      onError: (error) => {
        console.error("Error marking advances as paid:", error);
      },
    }
  );
  const location = useLocation();
  const { getQueryParam, setQueryParam, setMultipleQueryParams } =
    useQueryParams();
  const [page, setPage] = useState(
    getQueryParam("page") ? parseInt(getQueryParam("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam("pageSize") ? parseInt(getQueryParam("pageSize")) : 10
  );

  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [queryFilters, setQueryFilters] = useState({ page: 1 });
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  const { loading, error, data, refetch } = useQuery(ADMIN_ROSTERS_QUERY, {
    variables: { page, pageSize },
  });

  useEffect(() => {
    if (getQueryParam("page")) {
      setPage(parseInt(getQueryParam("page")));
    }
    refetch({
      page: getQueryParam("page") ? parseInt(getQueryParam("page")) : 1,
      pageSize: getQueryParam("pageSize")
        ? parseInt(getQueryParam("pageSize"))
        : 10,
      startDate: getQueryParamOrNull("startDate"),
      endDate: getQueryParamOrNull("endDate"),
    });
  }, [location.search]);

  const getQueryParamOrNull = (params) => {
    const value = getQueryParam(params);
    return value === "all" ? null : value;
  };

  const formik = useFormik({
    initialValues: {
      paidDate: new Date().toISOString().split("T")[0],
    },
    validationSchema,
    onSubmit: (values) => {
      if (showModal && selectedId) {
        roster_mark_as_paid({
          variables: {
            id: selectedId,
            paidDate: values.paidDate,
          },
        });
        setShowModal(false);
        refetch();
      }
    },
  });

  const bulkFormik = useFormik({
    initialValues: {
      paidDate: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (showBulkModal && selectedPeople.length > 0) {
        let ids = selectedPeople.map((p) => p.id);
        const variables = {
          ids: ids,
          paidDate: values.paidDate,
        };
        roster_mark_all_as_paid({
          variables: variables,
        });
        setShowBulkModal(false);
        refetch();
      }
    },
  });

  // useEffect(() => {
  //   refetch(queryFilters);
  // }, [queryFilters, refetch]);

  useEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 &&
      selectedPeople.length < data?.adminRosters.entries.length;
    setChecked(selectedPeople.length === data?.adminRosters.entries.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople]);

  const toggleAll = () => {
    setSelectedPeople(
      checked || indeterminate
        ? []
        : data?.adminRosters.entries.filter(
            (entry) => entry.status === "unpaid"
          )
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  // const handlePageClick = (e) => {
  //   setPage(e.selected + 1);
  //   refetch({ page: e.selected + 1, pageSize });
  // };
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    setQueryParam('page', e.selected + 1);
    // refetch({ page: newPage, pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <h1>Something went wrong!</h1>;

  const startOfResults = data.adminRosters.pageNumber
    ? (data.adminRosters.pageNumber - 1) * data.adminRosters.pageSize + 1
    : 0;

  const getFullName = (personnel) => {
    if (!personnel || !personnel.firstName || !personnel.lastName) {
      return "";
    }
    return `${personnel.firstName} ${personnel.lastName}`;
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <Layout>
      <div className="py-10 lg:pl-72">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Roster Payables
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the roster payables
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <Filter
            // setQueryFilters={setQueryFilters}
            setPage={setPage}
            filter={refetch}
          />
        </div>
        <div className="mt-8 flow-root overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative">
              {selectedPeople.length > 0 && (
                <div className="left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => {
                      setShowBulkModal(true);
                    }}
                  >
                    Bulk Pay
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th scope="col" className="process-th">
                      Date
                    </th>
                    <th scope="col" className="process-th">
                      Program
                    </th>
                    <th scope="col" className="process-th">
                      Personnel
                    </th>
                    <th scope="col" className="process-th">
                      Role
                    </th>
                    <th scope="col" className="process-th">
                      Rate
                    </th>
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      Tax %
                    </th> */}
                    <th scope="col" className="process-th">
                      Tax
                    </th>
                    <th scope="col" className="process-th">
                      Net Payout
                    </th>
                    <th scope="col" className="process-th">
                      Status
                    </th>
                    {/* <th
                      scope="col"
                      className="process-th"
                    >
                      <span className="sr-only">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.adminRosters.entries.map((item) => (
                    <tr
                      key={item.id}
                      className={
                        item.status === "unpaid" ? "bg-gray-50" : undefined
                      }
                    >
                      <td className="process-results relative">
                        {item.status === "unpaid" && (
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={item.id}
                            checked={selectedPeople.some(
                              (p) => p.id === item.id
                            )}
                            onChange={(e) =>
                              setSelectedPeople(
                                e.target.checked
                                  ? [...selectedPeople, item]
                                  : selectedPeople.filter(
                                      (p) => p.id !== item.id
                                    )
                              )
                            }
                          />
                        )}
                      </td>
                      <td className="process-results">
                        {moment(item.rosterDate).format("YYYY-MMM-DD")}
                      </td>
                      <td className="process-results">
                        {item?.program.programName}
                        <br />
                        {item?.program.clientName}
                      </td>
                      <td className="process-results">
                        {getFullName(item?.personnel)}
                      </td>
                      <td className="process-results">
                        {item?.roleType?.referenceDescription}
                      </td>
                      <td className="process-results text-right">
                        {formatNumber(item?.rate)}
                      </td>
                      {/* <td className="process-results">
                        {item.status !== "paid" ? (
                          formatNumber(item?.tax) ) : (
                            formatNumber((item.rateTaxAmount / item?.rate)*100))
                        }
                      </td> */}
                      <td className="process-results text-right">
                        {formatNumber(item.rateTaxAmount)}
                        <br />
                        {item.status !== "paid"
                          ? formatNumber(item?.tax)
                          : formatNumber(
                              (item.rateTaxAmount / item?.rate) * 100
                            )}{" "}
                        %
                      </td>
                      <td className="process-results text-right">
                        {formatNumber(item.netAmount)}
                      </td>
                      <td className="process-results">
                        {item.status === "paid" ? (
                          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            PAID
                          </span>
                        ) : (
                          // <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          //   UNPAID
                          // </span>
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedId(item.id);
                              setShowModal(true);
                            }}
                            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          >
                            Pay
                          </button>
                        )}
                      </td>
                      {/* <td className="process-results">
                        {item.status === "unpaid" && (
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedId(item.id);
                              setShowModal(true);
                            }}
                            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          >
                            Pay
                          </button>
                        )}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {data && (
              <div className="py-4 flex flex-row items-center justify-between mt-6">
                <div className="flex flex-wrap items-center justify-between text-sm text-gray-700">
                  <div className="flex items-center space-x-2">
                    <span>
                      Showing{" "}
                      <span className="font-medium">{startOfResults}</span> to
                    </span>
                    <label htmlFor="pageSize" className="sr-only">
                      Results per page:
                    </label>
                    <select
                      id="pageSize"
                      name="pageSize"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                        setQueryParam("pageSize", parseInt(e.target.value, 10));
                        // refetch({
                        //   page,
                        //   pageSize: parseInt(e.target.value, 10),
                        // });
                      }}
                      className="rounded-md bg-white py-1.5 px-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={1000}>1000</option>
                    </select>
                    <span>
                      of{" "}
                      <span className="font-medium">
                        {data.adminRosters.totalEntries}
                      </span>{" "}
                      results
                    </span>
                  </div>
                </div>

                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="sr-only">Next</span>
                      <svg
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <svg
                        className="h-5 w-5 mr-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={data.adminRosters.totalPages} // Total number of pages
                  forcePage={page - 1} // Sync with the current page (zero-indexed)
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                  pageClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  activeClassName="z-10 bg-indigo-600 text-white ring-1 ring-indigo-600"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
            )}
          </div>
        </div>

        {showModal && (
          <>
            <ModalAlert open={showModal} setOpen={setShowModal}>
              <form onSubmit={formik.handleSubmit}>
                {/* Modal content */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Mark as Paid
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Please enter the date to mark as paid.
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="paidDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Paid Date
                    </label>
                    <input
                      id="paidDate"
                      name="paidDate"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.paidDate}
                      max={currentDate}
                      className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formik.touched.paidDate && formik.errors.paidDate && (
                      <div className="text-red-600 text-sm">
                        {formik.errors.paidDate}
                      </div>
                    )}
                  </div>
                </div>
                {/* Modal actions */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </ModalAlert>
          </>
        )}

        {showBulkModal && (
          <ModalAlert open={showBulkModal} setOpen={setShowBulkModal}>
            <form onSubmit={bulkFormik.handleSubmit}>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Mark as Paid
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Please enter the date to mark as paid.
                </p>
                <div className="mt-4">
                  <label
                    htmlFor="bulkPaidDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Paid Date
                  </label>
                  <input
                    id="bulkPaidDate"
                    name="paidDate"
                    type="date"
                    onChange={bulkFormik.handleChange}
                    onBlur={bulkFormik.handleBlur}
                    value={bulkFormik.values.paidDate}
                    max={currentDate}
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {bulkFormik.touched.paidDate &&
                    bulkFormik.errors.paidDate && (
                      <div className="text-red-600 text-sm">
                        {bulkFormik.errors.paidDate}
                      </div>
                    )}
                </div>
                <div className="mt-4">
                  <h4 className="text-sm font-semibold leading-6 text-gray-900">
                    Selected Rosters:
                  </h4>

                  <ul className="border-t-gray-100 divide-y divide-gray-100">
                    {selectedPeople.map((person) => (
                      <li
                        key={person.id}
                        className="flex items-center justify-between gap-x-6 py-1.5"
                      >
                        <div className="min-w-0">
                          <div className="flex items-start gap-x-3">
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              {getFullName(person.personnel)}
                            </p>
                          </div>
                          <div className="mt-.5 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p className="whitespace-nowrap">
                              {person.program.clientName}
                            </p>
                            <svg
                              viewBox="0 0 2 2"
                              className="h-0.5 w-0.5 fill-current"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="truncate">
                              Date:{" "}
                              {moment(person.rosterDate).format("YYYY-MMM-DD")}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setShowBulkModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </ModalAlert>
        )}
      </div>
    </Layout>
  );
}
